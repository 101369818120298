@use "./components/TypesView/TypesView";

.media_page {
  padding: 30px 50px;
  // overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(25, minmax(auto, calc(100% / 25)));
  // grid-gap: 20px; /* Adjust as needed */
  grid-template-columns: 150px calc(100% - 100px - 50px);

  @media only screen and (max-width: 1589px) {
    padding: 25px 40px;
  }

  @media only screen and (max-width: 1091px) {
    padding: 22px 30px;
  }

  @media only screen and (max-width: 900px) {
    padding: 12px 0px;
    flex-direction: column;
    grid-template-columns: 100%;
    grid-gap: 20px;
  }

  .left {
    height: calc(100vh - 90px);

    @media only screen and (max-width: 900px) {
      height: 100%;
      padding: 0 8px;
    }
  }

  .right {
    width: 100%;
    height: calc(100dvh - 190px); // was140
    min-height: 550px; // to check
    max-height: 1000px; // to check
    position: relative;

    @media only screen and (max-width: 800px) {
      height: calc(100dvh - 226px);
      min-height: unset; // to check
    }



    .slider_tags {
      @media only screen and (max-width: 900px) {
        // height: 100%;
        padding: 20px 8px !important;
      }
    }
  }
}